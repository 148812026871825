<template>
  <div class="oa-page__wrapper">
    <div class="page-nav">
      <router-link
        v-for="page in childrenPages"
        class="page-nav__link"
        :key="page.name"
        :to="page.path"
        >{{ page.desc }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tools',
  components: {},
  data() {
    return {
      childrenPages: [
        {
          path: '/transform/tools/idCard',
          name: 'idCard',
          desc: '身份证查询'
        },
        {
          path: '/transform/tools/litigation',
          name: 'litigation',
          desc: '诉讼费'
        },
        {
          path: '/transform/tools/interest',
          name: 'interest',
          desc: '利息计算'
        },
        {
          path: '/transform/tools/deferredInterest',
          name: 'deferredInterest',
          desc: '延迟利息'
        },
        {
          path: '/transform/tools/computeDays',
          name: 'computeDays',
          desc: '天数计算'
        },
        {
          path: '/transform/tools/deathCompensation',
          name: 'deathCompensation',
          desc: '死亡赔偿'
        },
        {
          path: '/transform/tools/disabilityCompensation',
          name: 'disabilityCompensation',
          desc: '伤残赔偿'
        },
        {
          path: '/transform/tools/lawyerFee',
          name: 'lawyerFee',
          desc: '律师费'
        }
      ]
    }
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {}
}
</script>

<style lang="stylus">
.oa-page__wrapper
  position relative
  & .page-nav
    padding 20px
    & .page-nav__link
      display block
      display flex
      margin 0 0 12px
      padding-left 20px
      color #323233
      font-weight 500
      font-size 14px
      line-height 40px
      background #f7f8fa
      border-radius 99px
      &:hover
        background #eef0f4
</style>
